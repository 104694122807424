import styled from 'styled-components/macro';

export const Container = styled.div`
    margin: 0px auto;
    margin-top: 50px;
    background-color: #fff;
    border: 0px solid #e0e0e0;
    border-radius: 2px;
    width: 60%;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    font-family: 'Roboto';
    font-size: 20;

    @media screen and (max-width: 700px) {
        margin-bottom: 50px;
        max-width: 94vw;
        padding-left: 0px;
    }
`;

export const Title = styled.div`
    display: grid;
    justify-content: start;
    font-size: 24px;
    font-weight: 900;
    padding: 20px 10px 0px 50px;
    font-weight: 800;
    color: #444446;
    font-family: Roboto;
`;

export const Row1Modal = styled.div`
    display: grid;
    grid-area: Title;
`;

export const Row2Modal = styled.div`
    display: grid;
    grid-area: Filters;
`;
